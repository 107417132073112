<template>
  <div>
    <b-row class="mb-7">
      <b-col md="3"></b-col>
      <b-col md="6">
        <b-alert :show="updatePageFlag && team.name === 'admin'" variant="warning" class="text-center">
          {{ $t('description.you_can_choose_only_users_for_current_team') }}
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-input
            :label="$t('label.name')"
            v-model="form.name"
            :placeholder="$t('placeholder.enter_name')"
            required
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-multiselect
            :label="$t('label.ticket_types')"
            :placeholder="$t('placeholder.select_ticket_types')"
            :value="ticketTypes.filter(el => form.ticket_types.includes(el.id))"
            :options="ticketTypes"
            item-label="name"
            multiple
            required
            @select="form.ticket_types.push($event.id)"
            @remove="form.ticket_types = form.ticket_types.filter(el => el !== $event.id)"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-multiselect
            :label="$t('label.blocks')"
            :placeholder="$t('placeholder.select_blocks')"
            :value="blocks.filter(el => form.blocks.includes(el.id))"
            :options="blocks"
            multiple
            @select="form.blocks.push($event.id)"
            @remove="form.blocks = form.blocks.filter(el => el !== $event.id)"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-multiselect
            :label="$t('label.users')"
            :placeholder="$t('placeholder.select_users')"
            :value="users.filter(el => form.users.includes(el.id))"
            :options="users"
            multiple
            @select="form.users.push($event.id)"
            @remove="form.users = form.users.filter(el => el !== $event.id)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import AppInput from '@/components/form-groups/AppInput';
import AppMultiselect from '@/components/form-groups/AppMultiselect';

export default {
  components: {
    AppInput,
    AppMultiselect
  },
  props: {
    team: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    updatePageFlag: false,
    form: {
      name: '',
      users: [],
      blocks: [],
      ticket_types: []
    }
  }),
  beforeMount() {
    this.$store.dispatch('blocksStore/GET_BLOCKS');
    this.$store.dispatch('usersStore/GET_USERS');
    this.$store.dispatch('ticketTypesStore/GET_TYPES');
  },
  computed: {
    ...mapGetters({
      blocks: 'blocksStore/BLOCKS',
      users: 'usersStore/USERS',
      ticketTypes: 'ticketTypesStore/TYPES'
    })
  },
  watch: {
    team(data) {
      this.updatePageFlag = true;
      this.form.name = data.name;
      this.form.blocks = data.blocks.map(el => el.id);
      this.form.users = data.users.map(el => el.id);
      this.form.ticket_types = data.ticket_types.map(el => el.id);
    },
    form: {
      handler(data) {
        this.$emit('change', data);
      },
      deep: true
    }
  }
}
</script>